var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('error-disp',{model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"11","lg":"9"}},[_c('v-card',{staticClass:"elevation-4"},[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formatD(_vm.reportDate))+" ")]),_c('v-card-text',{staticClass:"text-body-1"},[_c('chart-card',{attrs:{"data":_vm.chartMonthlySales,"layout":{
                  xaxis: { dtick: 'M1' },
                  yaxis: { tickformat:',.3r', title: '$' },
                  height: 220,
                  font: {size: 11},
                  margin: {t: 10, b:30, r: 200},
                  bargap :0.15, barmode: 'stack'},"caption":"Sales value  by user.","download-title":"User_sales_value"}})],1),_c('v-card-text',{staticClass:"text-body-1"},[_c('chart-card',{attrs:{"data":_vm.chartMonthlySalesProp,"layout":{
                  xaxis: { dtick: 'M1' },
                  yaxis: { tickformat:',.0%', title: '%' },
                  height: 220,
                  font: {size: 11},
                  margin: {t: 10, b:30, r: 200},
                  bargap :0.15, barmode: 'stack'},"caption":"Proportion of sales value.","download-title":"User_sales_value_proportion"}})],1),_c('v-card-text',{staticClass:"text-body-1"},[_c('chart-card',{attrs:{"data":_vm.chartMonthlyValuePerSale,"layout":{
                  xaxis: { dtick: 'M1' },
                  yaxis: { tickformat:',.3r', title: '$' },
                  height: 220,
                  font: {size: 11},
                  margin: {t: 10, b:30, r: 200},
                  bargap :0.15, barmode: 'stack'},"caption":"Average value per sale.","download-title":"User_value_per_sale"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }